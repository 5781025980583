export default function PrivacyPolicy() {
  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-2xl font-semibold mb-6">Privacy Policy</h1>

      <div className="space-y-6 text-[15px] leading-relaxed">
        <p>
          Welcome to Bakpak AI ("we," "our," "us"). Your privacy is important to
          us, and we are committed to protecting the personal information you
          share with us. This Privacy Policy outlines how we collect, use, and
          protect your data when you use our AI-based automatic assessment
          generator platform ("Services") through our website [BakpakAI.com] or
          mobile application.
        </p>

        <div className="space-y-4">
          <h2 className="text-[15px] font-semibold">
            1. Information We Collect
          </h2>

          <div>
            <h3 className="text-[15px] font-semibold">
              1.1 Personal Information:
            </h3>
            <p className="mb-2">
              We may collect personal information that you provide, including
              but not limited to:
            </p>
            <ul className="list-disc pl-5 space-y-1">
              <li>Name, email address, phone number, and organization name.</li>
              <li>
                Payment and billing information if you purchase our Services.
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-[15px] font-semibold">
              1.2 Content Information:
            </h3>
            <ul className="list-disc pl-5 space-y-1">
              <li>
                Uploaded files, documents, or assessments used within the
                platform.
              </li>
              <li>User-provided inputs to generate assessments using AI.</li>
            </ul>
          </div>

          <div>
            <h3 className="text-[15px] font-semibold">
              1.3 Technical Information:
            </h3>
            <ul className="list-disc pl-5 space-y-1">
              <li>
                Device details, IP address, browser type, and operating system.
              </li>
              <li>
                Usage data such as pages accessed, time spent, and actions
                performed.
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-[15px] font-semibold">
              1.4 Cookies and Analytics:
            </h3>
            <p>
              Tracking technologies like cookies to improve user experience and
              analyze site usage.
            </p>
          </div>

          {/* <div>
            <h2 className="text-[15px] font-semibold">
              2. How We Use Your Information
            </h2>
            <p>We use your information for the following purposes:</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}
