const TermsAndConditions = () => {
  return (
    <>
      <h2 className="text-2xl font-medium mb-6">Terms & Conditions</h2>
      <div className="space-y-6 text-[15px] leading-relaxed">
        <p>
          Welcome to Bakpak AI ("we," "our," "us"). By using our AI-based
          automatic assessment generator platform ("Services"), you agree to
          these Terms and Conditions. Please read them carefully.
        </p>

        <div>
          <h3 className="font-medium mb-2">1. Use of Services</h3>
          <p>
            You must be at least 18 years old or have parental consent to use
            our Services.
          </p>
          <p>
            Your use of the Services must comply with all applicable laws and
            regulations.
          </p>
          <p>
            You are responsible for maintaining the confidentiality of your
            account credentials.
          </p>
        </div>

        <div>
          <h3 className="font-medium mb-2">2. Intellectual Property</h3>
          <p>
            All platform content, including AI algorithms, designs, and
            software, is owned by Bakpak AI.
          </p>
          <p>
            Users retain ownership of any data or content they upload but grant
            us a license to process it for Service purposes.
          </p>
        </div>

        <div>
          <h3 className="font-medium mb-2">3. Prohibited Activities</h3>
          <p>Users are prohibited from:</p>
          <p>
            Misusing the platform for illegal, harmful, or unauthorized
            purposes.
          </p>
          <p>Attempting to reverse-engineer or tamper with our technology.</p>
          <p>Uploading malicious, copyrighted, or infringing materials.</p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
