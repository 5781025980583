import { useState } from "react";

export default function PasswordSettings() {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle password update logic here
  };

  const handleDeactivate = () => {
    // Handle account deactivation logic here
  };

  return (
    <div className="w-full p-6">
      <h2 className="text-2xl font-semibold mb-6">Change password</h2>
      <form onSubmit={handleSubmit} className="w-[50%] space-y-6">
        <div className="flex items-center justify-between space-x-4 w-full">
          <label
            htmlFor="currentPassword"
            className="block text-sm font-medium text-gray-700 mb-1 text-nowrap w-40"
          >
            Current password
          </label>
          <input
            type="password"
            id="currentPassword"
            name="currentPassword"
            value={formData.currentPassword}
            onChange={handleChange}
            className="px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>

        <div className="flex items-center justify-between space-x-4">
          <label
            htmlFor="newPassword"
            className="block text-sm font-medium text-gray-700 mb-1 text-nowrap w-40"
          >
            New password
          </label>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            className="px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>

        <div className="flex items-center justify-between space-x-4">
          <label
            htmlFor="confirmPassword"
            className="block text-sm font-medium text-gray-700 mb-1 text-nowrap w-40"
          >
            Confirm new password
          </label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            className="px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>

        <button
          type="submit"
          className="w-48 bg-blue-500 text-white py-2 px-4 rounded-md"
        >
          Update Password
        </button>
      </form>

      <div className="mt-12 bg-gray-200 rounded p-6">
        <h3 className="text-lg font-medium text-custom-pink-1 mb-2">
          Deactivate account
        </h3>
        <div className="flex spacex-x-6 items-start justify-between">
          <p className="text-sm text-gray-500 mb-4">
            Once you delete your account, there is no going back. Please be
            certain.
          </p>
          <div className="flex justify-between items-center">
            <button
              onClick={handleDeactivate}
              className="bg-custom-pink-1 text-white py-1 px-4 rounded-md text-base font-medium h-12"
            >
              Deactivate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
