import { User2, Settings2, Shield, FileText, LogOut } from "lucide-react";
import { NavLink, Outlet } from "react-router-dom";

const navItems = [
  {
    icon: User2,
    label: "Profile",
    route: "profile",
  },
  {
    icon: Settings2,
    label: "Settings",
    route: "password_setting",
  },
  {
    icon: Shield,
    label: "Privacy Policy",
    route: "privacy_policy",
  },
  {
    icon: FileText,
    label: "Terms & conditions",
    route: "terms",
  },
];

export default function SettingsLayout() {
  // const navigate = useNavigate();
  return (
    <div className="flex h-full bg-white rounded-3xl">
      {/* Left Navigation */}
      <div
        className="w-[300px] h-full bg-white p-8 pt-14"
        style={{ top: 0, bottom: 0 }}
      >
        <h1 className="text-3xl font-medium mb-8">Settings</h1>

        <div className="space-y-4">
          {navItems.map((item) => (
            <NavLink
              key={item.label}
              to={item.route}
              className={({ isActive }) =>
                `flex items-center w-full gap-3 p-2 rounded-lg hover:bg-gray-50 ${
                  isActive ? "bg-[#F5F8FF]" : ""
                }`
              }
            >
              <div className="w-8 h-8 flex items-center justify-center bg-[#0A2357] rounded-full">
                <item.icon className="w-4 h-4 text-white" />
              </div>
              <span className="text-[18px] font-semibold">{item.label}</span>
            </NavLink>
          ))}
        </div>

        {/* Logout Button */}
        <button className="flex items-center w-full gap-3 p-2 mt-20 rounded-lg bg-gray-50 hover:bg-gray-100 bottom-8">
          <div className="w-8 h-8 rounded-full overflow-hidden">
            <img
              src="/avatar_1.jpg"
              alt="Profile"
              className="w-full h-full object-cover"
            />
          </div>
          <span className="text-[15px]">Logout</span>
        </button>
      </div>

      {/* Right Content */}
      <div
        className="flex-1 p-8 bg-custom-gray-4 rounded"
        style={{ marginTop: "40px", overflowY: "auto" }}
      >
        <Outlet />
      </div>
    </div>
  );
}
