import React, { Suspense } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import AuthGuard from "./AuthGuard";
import "react-toastify/dist/ReactToastify.css";
import SettingLayout from "./pages/SettingLayout";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PasswordSettings from "./pages/PasswordSetting";
import ProfileSettings from "./pages/Profile";

const SideBar = React.lazy(() => import("./components/SideBar"));
const AssessmentHome = React.lazy(() => import("./components/AssessmentHome"));
const DashboardPage = React.lazy(() => import("./components/DashboardPage"));
const GenerateQuestions = React.lazy(() =>
  import("./components/GenerateQuestions")
);
const AssessmentInfo = React.lazy(() => import("./components/AssessmentInfo"));
const SubAssessment = React.lazy(() => import("./components/SubAssessment"));
const AssessmentData = React.lazy(() => import("./components/AssessmentData"));
const ResultHome = React.lazy(() => import("./components/ResultHome"));
const ResultInfo = React.lazy(() => import("./components/ResultInfo"));
const AssessmentDetails = React.lazy(() =>
  import("./components/AssessmentDetails")
);
const LoginPage = React.lazy(() => import("./components/LoginPage"));
const SignUpPage = React.lazy(() => import("./components/SignUpPage"));
const LandingPage = React.lazy(() => import("./pages/LandingPage"));
const PricingPage = React.lazy(() => import("./pages/PricingPage"));
const PaymentSuccess = React.lazy(() => import("./pages/PaymentSuccess"));
const NotificationPage = React.lazy(() => import("./pages/Notifications"));
const StartAssignment = React.lazy(() =>
  import("./components/StartAssignment")
);

const App = () => {
  return (
    <div>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route
              path="payment-success"
              element={
                <AuthGuard>
                  <PaymentSuccess />
                </AuthGuard>
              }
            />

            <Route
              path="/home"
              element={
                <AuthGuard>
                  <SideBar />
                </AuthGuard>
              }
            >
              <Route
                path="notifications"
                element={
                  <AuthGuard>
                    <NotificationPage />
                  </AuthGuard>
                }
              />
              <Route path="settings" element={<SettingLayout />}>
                <Route path="terms" element={<TermsAndConditions />} />
                <Route path="privacy_policy" element={<PrivacyPolicy />} />
                <Route
                  index
                  path="password_setting"
                  element={<PasswordSettings />}
                />
                <Route index path="profile" element={<ProfileSettings />} />
              </Route>
              <Route
                path="assessment"
                element={
                  <AuthGuard>
                    <AssessmentHome />
                  </AuthGuard>
                }
              />
              <Route
                path="assessment/sub/:grade"
                element={
                  <AuthGuard>
                    <SubAssessment />
                  </AuthGuard>
                }
              />
              <Route
                path="assessment/sub/:grade/:sub"
                element={
                  <AuthGuard>
                    <AssessmentData />
                  </AuthGuard>
                }
              />
              <Route
                path="assessment/add"
                element={
                  <AuthGuard>
                    <GenerateQuestions />
                  </AuthGuard>
                }
              />
              <Route
                path="assessment/info"
                element={
                  <AuthGuard>
                    <AssessmentInfo />
                  </AuthGuard>
                }
              />
              <Route
                path="assessment/details"
                element={
                  <AuthGuard>
                    <AssessmentDetails />
                  </AuthGuard>
                }
              />
              <Route
                path="dashboard"
                element={
                  <AuthGuard>
                    <DashboardPage />
                  </AuthGuard>
                }
              />
              <Route
                path="result"
                element={
                  <AuthGuard>
                    <ResultHome />
                  </AuthGuard>
                }
              />
              <Route
                path="result/info"
                element={
                  <AuthGuard>
                    <ResultInfo />
                  </AuthGuard>
                }
              />
              <Route path="" element={<Navigate to="assessment" />} />
            </Route>
            <Route path="/" element={<LandingPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="start-assignment" element={<StartAssignment />} />
            <Route path="sign-up" element={<SignUpPage />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="" element={<Navigate to="login" />} />
            <Route path="pricing" element={<PricingPage />} />
          </Routes>
        </Suspense>
      </Router>
      <ToastContainer />
    </div>
  );
};

export default App;
