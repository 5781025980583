import { useState } from "react";

export default function ProfileSettings() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    grade: "",
    school: "",
  });

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="max-w-3xl mx-auto p-6">
      {/* Profile Header */}
      <div className="bg-[#F5F8FF] rounded-xl p-4 mb-8 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="w-12 h-12 rounded-full overflow-hidden">
            <img
              src="/avatar_1.jpg"
              alt="Profile"
              width={48}
              height={48}
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            <h2 className="text-lg font-semibold">John Doe</h2>
            <button className="text-sm text-gray-500 hover:text-gray-700">
              View Profile
            </button>
          </div>
        </div>
        <button className="bg-blue-500 text-white px-6 py-2 rounded-md text-sm hover:bg-blue-600 transition-colors">
          Upload
        </button>
      </div>

      {/* Contact Information Form */}
      <div>
        <h3 className="text-xl font-semibold mb-6">Contact information</h3>
        <form className="grid grid-cols-2 gap-6">
          <div>
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-md placeholder-gray-400"
            />
          </div>

          <div>
            <label
              htmlFor="grade"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Grade
            </label>
            <input
              type="text"
              id="grade"
              name="grade"
              placeholder="Grade"
              value={formData.grade}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-md placeholder-gray-400"
            />
          </div>

          <div>
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-md placeholder-gray-400"
            />
          </div>

          <div>
            <label
              htmlFor="school"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              School
            </label>
            <input
              type="text"
              id="school"
              name="school"
              placeholder="School"
              value={formData.school}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-md placeholder-gray-400"
            />
          </div>

          <div className="col-span-2 flex justify-end mt-4">
            <button
              type="submit"
              className="bg-blue-500 text-white px-8 py-2 rounded-md text-sm hover:bg-blue-600 transition-colors"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
